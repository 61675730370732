import React from "react";
import payments from "./Service"
import i18n from "../../../i18n"
import { paymentsService } from "../../Payments/Service"
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import config from "../../../config/config";
import Popup from "../../../components/UI/Popup/Popup";
import { connect } from "react-redux";

class PaymentsMode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentsMode: [],
            isBoarded: false,
        };
    }
    
    componentDidMount() {
        const deliveryMode = this.props.cart.deliveryMode;
        const storeId = this.props.cart.storeId;
        payments(deliveryMode, storeId)
            .then(response => {
                this.setState({
                    paymentsMode: response.data,
                })
            }).catch((error) => {
                console.log(error);
            })

        this.onFocus()
        // window.addEventListener("focus", this.onFocus)
        paymentsService.paymenthMethods()
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "XPAY") {
                            this.setState({ isBoarded: true })
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
        window.addEventListener("focus", this.onFocus)
        paymentsService.startBoarding()
            .then(response => {
                // Inizializzazione SDK
                XPay.init();

                // Oggetto contenente la configurazione del pagamento
                var configuration = {
                    baseConfig: {
                        apiKey: process.env.REACT_APP_NEXI_APIKEY,
                        enviroment: XPay.Environments.PROD
                    },
                    paymentParams: {
                        amount: "0",
                        transactionId: response.transactionCode,
                        currency: response.currency,
                        timeStamp: response.timestamp,
                        mac: response.mac,
                        urlPost: `${config.baseUrl}/cli/pay/v2/xpay/board?tid=01&profileId=${this.props.profileId}`
                    },
                    customParams: {
                        num_contratto: response.contractNumber,
                        tipo_servizio: 'paga_oc3d',
                        tipo_richiesta: 'PP'
                    },
                    language: XPay.LANGUAGE.ITA,

                };

                // Configurazione lightbox
                XPay.initLightbox(configuration);
            })
            .catch(error => { console.log(error); })
            window.addEventListener("XPay_Payment_Result", this.onFocus);
    }

    /* componentWillUnmount() {
        window.removeEventListener("focus", this.onFocus)
    } */

    onFocus = () => {
        paymentsService.paymenthMethods()
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "XPAY") {
                            this.setState({ isBoarded: true })
                            this.props.setPaymentMethod("XPAY")
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    removePaymenMethod = () => {
        paymentsService.removePaymentMethod()
            .then(response => {
                this.setState({
                    paymentMethods: response.data,
                    isBoarded: false
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    renderRemoveCard = () => {
        return (
            <Translation>
                {t =>
                    <Popup
                        action={() => this.removePaymenMethod()}
                        title={i18n.t("payment.remove.title")}
                        content={i18n.t("payment.remove.question")}
                        button={i18n.t("payment.remove")}
                        trigger={
                            <button style={{ backgroundColor: "#fff", border: "1px solid #fff", fontSize: "14px" }}>
                                Rimuovi metodo di pagamento
                            </button>
                        }
                    />
                }
            </Translation>
        )
    }

    renderButtons = (paymentMode) => {
        const { paymentMethod, setPaymentMethod } = this.props;
        const { isBoarded } = this.state;
        switch (paymentMode) {
            case "CASH":
                return (
                    <div className="d-flex">
                        <div className="radio-button mr-3">
                            <div className="custom control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="CASH"
                                    name="CASH"
                                    value="CASH"
                                    checked={paymentMethod === "CASH"}
                                    onChange={() => setPaymentMethod("CASH")}
                                />
                                <label className="custom-control-label" style={{ textTransform: "uppercase" }} htmlFor="CASH">
                                    <h5>{i18n.t("paymentMode.cash.title")}</h5>
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case "BANCOMAT":
                return (
                    <div className="d-flex">
                        <div className="radio-button mr-3">
                            <div className="custom control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="BANCOMAT"
                                    name="BANCOMAT"
                                    value="BANCOMAT"
                                    checked={paymentMethod === "BANCOMAT"}
                                    onChange={() => setPaymentMethod("BANCOMAT")}
                                />
                                <label className="custom-control-label" style={{ textTransform: "uppercase" }} htmlFor="BANCOMAT">
                                    <h5>{i18n.t("paymentMode.bancomat.title")}</h5>
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case "XPAY":
                if (isBoarded) {
                    return (
                        <div className="d-flex" style={{ justifyContent: "space-between" }}>
                            <div className="radio-button mr-3">
                                <div className="custom-control custom-radio">
                                    <input type="radio" className="custom-control-input"
                                        id="XPAY"
                                        name="XPAY"
                                        value="XPAY"
                                        checked={paymentMethod === "XPAY"}
                                        onChange={() => setPaymentMethod("XPAY")}
                                    />
                                    <label className="custom-control-label" style={{ textTransform: "uppercase" }} htmlFor="XPAY">
                                        <h5>{i18n.t("paymentMode.stripe.title")}</h5>
                                    </label>
                                </div>
                            </div>
                            {this.renderRemoveCard()}
                        </div>
                    );
                } else {
                    return (
                        this.renderAllocateCard()
                    );
                }
            default: return null;
        }
    }

    handlePayment = () => {
        paymentsService.boarding()
            .then(response => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response], {
                    type: 'text/html'
                }));
                window.open(url, '_blank');
                // const link = document.createElement('a');
                // link.href = url;
                // document.body.appendChild(link);
                // link.click();
                // link.remove();
            })
            .catch(error => {
                console.log(error);
            })
    }

    renderAllocateCard = () => {
        return (
            <Translation>
                {t =>
                    <Popup
                        action={() => XPay.openLightbox()}
                        title={i18n.t("payment.allocate")}
                        content={i18n.t("payment.allocate.question")}
                        button={i18n.t("confirm")}
                        trigger={
                            <a rel="noopener noreferrer">
                                <div className="addMethod" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#d80014' }}>
                                    Aggiungi metodo di pagamento online
                                </div>
                            </a>
                        }
                    />
                }
            </Translation>
        )
    }

    render() {
        const { paymentsMode } = this.state;
        return (
            <Translation>
                {t =>
                    <div style={{ marginTop: "20px" }}>
                        <h4 style={{ marginBottom: "15px" }}>{t("checkout.paymentsMode.title")}</h4>
                        {paymentsMode && paymentsMode.map(paymentMode => (
                            <div style={{ marginBottom: "15px" }} key={paymentMode}>
                                {this.renderButtons(paymentMode)}
                            </div>
                        ))}
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        profileId: state.user?.user?.id
    };
}

export default connect(mapStateToProps)(PaymentsMode);


