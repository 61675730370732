import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../../components/UI/Breadcrumb/CustomBreadcrumb";
import CartItem from "../CartItem/CartItem";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import "./current-cart.styles.scss";
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GoBackButton from "../../../components/UI/Buttons/GoBackButton";
import renderPrice from "../../../utils/renderPrice";
import Popup from "../../../components/UI/Popup/Popup";
import { useDispatch } from "react-redux";
import * as actionCreators from "../../../redux/store/actions/index";
import { ReactComponent as Cal } from "../../../images/cal-icon.svg";
import { ReactComponent as AccordionDown } from "../../../images/accordion-down.svg";
import { ReactComponent as AccordionUp } from "../../../images/accordion-up.svg";
import { ReactComponent as Alert } from "../../../images/card-alert.svg"
import CartMenu from "../CartMenu/CartMenu";

const CurrentCart = ({
    cart,
    freezeCheck,
    abort,
    showFreezePopup,
    closeFreezePopup,
    updatedItems,
    removedItems,
    togglePopup,
    choosedDay,
    choosedTimeslot,
    termsAndCondition,
    goToCheckout,
    paymentMethod,
    goToAvailability,
    user
}) => {
    const { t } = useTranslation();
    const categories = useSelector(state => state.cart.categories);
    const [items, setItems] = useState([]);
    const [couponCode, setCouponCode] = useState("")
    const [alternativeAll, setAlterantiveAll] = useState(false);
    const [replacemntProduct, setReplacemntProduct] = useState(false);
    let history = useHistory();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false)
    const [disabled, setDisabled] = useState(cart?.netTotal < cart.minNetTotal)

    useEffect(() => {
        const result = [];
        categories.forEach(category => {
            const cartItems = [];
            cart && cart.items && cart.items.length > 0 && cart.items.forEach(cartItem => {
                const categoryItem = cartItem.categoryId ? cartItem.categoryId : "";
                let categoryIdItem;
                if (categoryItem?.includes("_")) {
                    categoryIdItem = categoryItem.substring(0, categoryItem.indexOf("_"));
                } else {
                    categoryIdItem = categoryItem;
                }
                if (categoryIdItem === category.id) {
                    cartItems.push(cartItem);
                }
            });
            if (cartItems.length > 0) {
                result.push({
                    title: category.description,
                    cartItems: cartItems
                })
                setDisabled(cart?.netTotal < cart.minNetTotal);
            }
        });
        setItems(result);
        checkAlternative();
        renderCartItem();
    }, [categories, cart])

    useEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);
    }, []);

    const handleResize = () => {
        if (window.innerWidth > 1279) {
            setIsOpen(false);
        }
    }

    const handleCoupon = (coupon, action, couponId) => {
        dispatch(actionCreators.manageCoupon(cart.id, coupon, action, couponId));
        setCouponCode("")
    }

    const renderMinNetTotal = () => {
        if (cart && cart.minNetTotal && cart.minNetTotal > 0 && cart?.netTotal < cart.minNetTotal) {
            return (
                <div className="current-cart-minNetTotal">
                    <Alert />
                    <div>
                        {t("cartMenu.minimumItemsToOrder")} : <b>{`${cart && cart.minNetTotal && cart.minNetTotal > 0 && cart.minNetTotal?.toFixed(2)} \u20AC`}</b>
                    </div>
                </div>
            );
        } else return null;
    }

    const checkAlternative = () => {
        let oneEnabled = false;
        let noReplacemnt = true;
        if (cart.itemsCount > 0) {
            cart.items.map(item => {
                if (item.replacementBarcode && item.enableReplacement) {
                    oneEnabled = true;
                }
            });
            setAlterantiveAll(oneEnabled);
            if (!oneEnabled) {
                cart.items.map(item => {
                    if (item.replacementBarcode) {
                        noReplacemnt = false;
                    }
                });
                setReplacemntProduct(noReplacemnt);
            }
        }
    }

    const handleAlternative = (checked) => {
        // if (cart.itemsCount > 0) {
        //     cart.items.map(item => {
        //         if (item.replacementBarcode) {
        //             dispatch(actionCreators.store(cart.id, item.barcode, item.amount, item.purchaseUm, checked))
        //             dispatch(actionCreators.current())
        //         }
        //     })
        // }
        dispatch(actionCreators.massiveReplacement(cart.id, checked));
    }

    const renderCartItem = () => {
        return (
            items && items.map((item, index) => (
                <React.Fragment key={index}>
                    <div className="current-cart-category">{item.title}</div>
                    {item.cartItems && item.cartItems.map((cartItem, cartIndex) => (
                        <div key={cartItem.id}>
                            <CartItem key={cartItem.id} item={cartItem} alternative={cartItem.enableReplacement} />
                            {item.cartItems.length !== cartIndex + 1 &&
                                <div className="d-block d-sm-none">
                                    <hr />
                                </div>
                            }
                        </div>
                    ))}
                </React.Fragment>

            ))
        )
    }

    return (
        <div className="current-cart">
            {renderMinNetTotal()}
            <div className="current-cart-heading-bar desktop-cart">
                <div className="col-lg-6 col-sm-5 col-md-4 current-cart-heading-bar-text">
                    <span className="current-cart-heading-bar-text-digit-text">1</span>
                    <span className="current-cart-heading-bar-text-alpha-text">CARRELLO</span>
                </div>
                <div className="col-lg-6 col-sm-7 col-md-8 current-cart-heading-bar-buttons">
                    <button className="shoppingButton"
                        onClick={() => history.push("/home")}>
                        CONTINUA LO SHOPPING
                    </button>
                    <button className="avanti-button"
                        onClick={goToAvailability}
                        disabled={disabled}
                    >
                        AVANTI
                    </button>
                </div>
            </div>
            {!cart.coupons && cart.itemsCount > 0 &&
                <div className="current-cart-coupon desktop-cart">
                    <div className="current-cart-coupon-label">
                        Inserisci il codice del Buono Sconto
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <form onSubmit={e => {
                            e.preventDefault();
                            handleCoupon(couponCode, "ADD")
                        }}
                            className="coupon-form"
                        >
                            <input className="coupon-input" onChange={(e) => setCouponCode(e.target.value.toUpperCase())} value={couponCode}
                            ></input>
                            <button type="submit" className="coupon-apply-button">
                                <span>AGGIUNGI</span>
                            </button>
                        </form>
                    </div>
                </div>
            }
            {cart.itemsCount > 0 && !replacemntProduct &&
                <div className="current-cart-alternative">
                    <div style={{ padding: '5px' }}>
                        <div className="substitutediv">
                            <div className="substitute">
                                <label className="switch">
                                    <input type="checkbox" checked={alternativeAll} onChange={(e) => handleAlternative(e.target.checked)} />
                                    <span className="slider round mobile-slider"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="text">
                        <div className="col-12 current-cart-alternative-label">
                            Accetto Prodotto alternativo
                        </div>
                        <div className="col-12 current-cart-alternative-sublabel">
                            {alternativeAll ? <>Hai scelto di ricevere i prodotti alternativi nel caso in cui quelli scelti non fossero disponibili. Puoi comunque deselezionarli singolarmente.</> : <>Hai scelto di non ricevere nessun prodotto alternativo. Puoi comunque selezionarli singolarmente</>}
                        </div>
                    </div>
                </div>
            }
            <div className="cart-menu-dropdown desktop">
                <div className="col-6" style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <Cal className="mr-4" />
                    <h5>RIEPILOGO</h5>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? <AccordionUp /> : <AccordionDown />}
                </div>
            </div>
            {
                isOpen &&
                <div className="desktop">
                    <CartMenu
                        cart={cart}
                        freezeCheck={freezeCheck}
                        abort={abort}
                        open={showFreezePopup}
                        closePopup={closeFreezePopup}
                        updatedItems={updatedItems}
                        removedItems={removedItems}
                        togglePopup={togglePopup}
                        choosedDay={choosedDay}
                        choosedTimeslot={choosedTimeslot}
                        termsAndCondition={termsAndCondition}
                        goToCheckout={goToCheckout}
                        paymentMethod={paymentMethod}
                        user={user}
                        mobile={false}
                    />
                </div>
            }
            <div className="current-cart-heading d-none d-md-block">
                <div className="row no-gutters">
                    <div style={{ width: "65px" }}>{t("products")}</div>
                    <div className="col pl-0 pr-3">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-4 text-left px-0" />
                                <div className="col-2 text-center px-0">
                                    {t("currentCart.price")}
                                </div>
                                {/* <div className="col-1"></div> */}
                                <div className="col-3 text-center px-0">{t("amount")}</div>
                                <div className="col-2 text-right text-sm-center px-0">{t("total")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {cart && cart.items && cart.items.map((item, index) => (
                <div key={item.id}>
                    <CartItem key={item.id} item={item} />
                    {cart.items.length !== index + 1 &&
                        <div className="d-block d-sm-none">
                            <hr />
                        </div>
                    }
                </div>
            ))} */}

            {renderCartItem()}

            {!cart.coupons && cart.itemsCount > 0 &&
                <div className="current-cart-coupon mobile-cart">
                    <div className="current-cart-coupon-label">
                        Inserisci il codice del Buono Sconto
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <form onSubmit={e => {
                            e.preventDefault();
                            handleCoupon(couponCode, "ADD")
                        }}
                            className="coupon-form"
                        >
                            <input className="coupon-input" onChange={(e) => setCouponCode(e.target.value.toUpperCase())} value={couponCode}
                            ></input>
                            <button type="submit" className="coupon-apply-button">
                                <span>AGGIUNGI</span>
                            </button>
                        </form>
                    </div>
                </div>
            }
            {cart.coupons && cart.itemsCount > 0 &&
                <div className="current-cart-coupon mobile-cart">
                    <div className="current-cart-coupon-label">
                        Buono sconto inserito
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <form onSubmit={e => {
                            e.preventDefault();
                            handleCoupon(cart.coupons[0].code, "DELETE", cart.coupons[0].allocatedCouponId)
                        }}
                            className="coupon-form"
                        >
                            <span style={{ fontFamily: 'Montserrat-Bold' }}>{cart.coupons[0].description}</span>
                            <button type="submit" className="coupon-apply-button">
                                <span>RIMUOVI</span>
                            </button>
                        </form>
                    </div>
                </div>
            }
            <div className="current-cart-fees mobile-cart">
                <div>
                    <div className="fee">
                        <span>{t("cart.totalAmount")} ({cart?.itemsCount})</span>
                        <span className="total">{cart && `\u20AC ${renderPrice(cart.netTotal?.toFixed(2))}`}</span>
                    </div>
                    {cart && cart.coupons &&
                        <div className="fee">
                            <span>BUONO SCONTO</span>
                            <span className="total">{cart && `- \u20AC ${renderPrice(cart.coupons[0].value?.toFixed(2))}`}</span>
                        </div>
                    }
                    <hr />
                    <div className="fee total">
                        <span>{t("total")}</span>
                        <span>{cart && cart.totalToPay ? `\u20AC ${renderPrice(cart.totalToPay?.toFixed(2))}` : `\u20AC ${renderPrice(cart.netTotal?.toFixed(2))}`}</span>
                    </div>
                </div>
            </div>
            <div className="mobile-cart abort">
                <Popup
                    action={() => abort(cart.id)}
                    title={t("cart.delete")}
                    content={t("cart.delete.question")}
                    button={t("delete")}
                    trigger={
                        <div className="tiny-cta">
                            {t("cart.delete")}
                        </div>
                    }
                />
            </div>
            <div className="cart-menu-dropdown mobile-cart">
                <div className="col-6" style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <Cal className="mr-4" />
                    <h5>RIEPILOGO</h5>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? <AccordionUp /> : <AccordionDown />}
                </div>
            </div>
            {
                isOpen &&
                <div className="mobile-cart">
                    <CartMenu
                        cart={cart}
                        freezeCheck={freezeCheck}
                        abort={abort}
                        open={showFreezePopup}
                        closePopup={closeFreezePopup}
                        updatedItems={updatedItems}
                        removedItems={removedItems}
                        togglePopup={togglePopup}
                        choosedDay={choosedDay}
                        choosedTimeslot={choosedTimeslot}
                        termsAndCondition={termsAndCondition}
                        goToCheckout={goToCheckout}
                        paymentMethod={paymentMethod}
                        user={user}
                        mobile={true}
                    />
                </div>
            }
            {cart.itemsCount > 0 &&
                <div className="current-cart-heading-bar">
                    <div className="col-lg-6 col-sm-5 col-md-4 current-cart-heading-bar-text">
                        <span className="current-cart-heading-bar-text-digit-text">1</span>
                        <span className="current-cart-heading-bar-text-alpha-text">CARRELLO</span>
                    </div>
                    <div className="col-lg-6 col-sm-7 col-md-8 current-cart-heading-bar-buttons">
                        <button className="shoppingButton"
                            onClick={() => history.push("/home")}>
                            CONTINUA LO SHOPPING
                        </button>
                        <button className="avanti-button"
                            onClick={goToAvailability}
                            disabled={disabled}
                        >
                            AVANTI
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}
export default CurrentCart;